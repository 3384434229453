@import "_bootstrap.scss";
@import "sprite.scss";

body {
  font-family: "open sans";
  line-height: 1.5;
}
a {
  cursor: pointer;
}

h2 {
  &.big {
    font-size: 40px;
    margin: 70px 0 40px;
  }
}

.modal-content {
  padding: 0px;
  box-shadow: 0px 11px 30px 6px #333;
  border: 0;
}
.modal-title {
  font-size: 30px;
  background-color: #7db40a;
  color: #fff;
  padding: 25px 40px;
}
label {
  font-weight: 600;
}
img[data-original] {
  cursor: pointer;
}
@import "box/box-advantages.scss";
@import "box/box-banner.scss";
@import "box/box-complect.scss";
@import "box/box-delivery-price.scss";
@import "box/box-editable-content.scss";
@import "box/box-footer.scss";
@import "box/box-header.scss";
@import "box/box-price-table.scss";
@import "box/box-query.scss";
@import "box/box-slider-tabs.scss";
@import "box/box-step.scss";
@import "elem/elem-advantage.scss";
@import "elem/elem-boot_line.scss";
@import "elem/elem-button.scss";
@import "elem/elem-complect.scss";
@import "elem/elem-expensive.scss";
@import "elem/elem-file.scss";
@import "elem/elem-hint.scss";
@import "elem/elem-img.scss";
@import "elem/elem-radio.scss";
@import "elem/elem-slider-btn.scss";
@import "nav/nav-header.scss";
@import "nav/nav-side.scss";
@import "plugins/backtop.scss";
@import "plugins/font-awesome.scss";
@import "plugins/jquery.fancybox-plus.scss";
@import "plugins/rafa-std.scss";
@import "plugins/youtube.scss";