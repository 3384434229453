.box-editable-content {
	dt {
		padding-bottom: 10px;
		a {
			font-size: 18px;
			&:focus {
				outline: none;
				color: #0035ac;
			}
		}
	}
	dd {
		padding: 0 0 10px 10px;
	}
	img {
		padding: 0 20px 0 0;
	}
	p {
		line-height: 1.5;
		margin-bottom: 16px;
	}
	a {
		&.hover {
			color: $link-hover-color;
		}
		&:visited {

		}
	}
	ul {
		margin: 0 0 11px 0;
		padding: 0;
		list-style: none;
		font-size: 14px;
		li {
			position: relative;
			padding-left: 10px;
			margin-bottom: 5px;
			&:before {
				content: '';
				display: block;
				width: 3px;
				height: 3px;
				border-radius: 50%;
				background-color: #333;
				position: absolute;
				left: 0px;
				top: 9px;
			}
		}
	}
	ol {
		margin: 0 0 11px 0;
		padding: 0;
		list-style: none;
		font-size: 14px;
		counter-reset: li;
		li {
			position: relative;
			padding-left: 17px;
			margin-bottom: 4px;
			&:before {
				counter-increment: li;
				content: counters(li,".") ". ";
				position: absolute;
				left: 1px;
			}
			> ol {
				margin-bottom: 0;
				li {
					margin-top: 6px;
					margin-bottom: 0;
					padding-left: 30px;
					&:before {
						left: 0;
					}
				}
			}
		}
	}
}