.nav-header {
	&.display-none {
		display: none;
	}
	/*position: fixed;*/
	right: 0;
	left: 0;
	z-index: 1030;
	font-size: 0;
	height: 46px;
	background-color: #eee;
	a, span {
		display: inline-block;
		font-size: 15px;
		color: #0035ac;
		text-decoration: underline;
	}
	a {
		&:hover {
			color: #cc0000;
		}
	}
	.nav-scroll {
		a, span {
			padding: 13px 15px 21px 15px;
			margin-right: 22px;
			margin-top: -1px;
			color: #333;
			text-decoration: none;
			&.active {
				background-color: #7db40a;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				color: #fff;
				text-decoration: none;
				cursor: default;
			}
			&:first-child {
				margin-left: -15px;
			}
		}
		.logo {
			margin-right: 0px;
		}
		.phone {
			font-family: Open Sans;
			font-weight: 600;
			font-size: 22px;
			margin-bottom: -2px;
			letter-spacing: -.01em;
			margin-top: -6px;
			position: absolute;
		}
		.elem-button-callback {
			position: absolute;
			top: 10px;
			margin-left: 240px;
		}
	}
	nav {
		a, span {
			padding: 12px 15px;
			margin-right: 22px;
			margin-top: -1px;
			&.active {
				background-color: #7db40a;
				border-bottom-left-radius: 4px;
				border-bottom-right-radius: 4px;
				color: #fff;
				text-decoration: none;
				cursor: default;
			}
			&:first-child {
				margin-left: -15px;
			}
		}
	}
	.elem-dropdown {
		display: inline-block;
		padding: 0 15px 0 0;
		margin-top: -1px;
		position: relative;
		cursor: pointer;
		border: solid 1px transparent;
		border-bottom: none;
		> a {
			padding-left: 15px;
			padding-right: 0;
			padding-bottom: 17px;
			margin-left: 0 !important;
			margin-right: 0;
			position: relative;
			z-index: 3;
		}
		> .fa {
		    margin-left: 9px;
		    font-size: 15px;
		    position: relative;
		    top: -3px;
		    color: #0035ac;
		}
		.drop-body {
			display: none;
			position: absolute;
			padding: 15px;
			padding-bottom: 22px;
			left: -1px;
			top: 100%;
			margin-top: -1px;
			background-color: #fff;
			width: 250px;
			border: solid 1px #b1b1b1;
			z-index: 2;
			a {
				padding: 0;
				margin: 0 0 6px 0;
				&:first-child {
					margin-left: 0;
				}
			}
			ul {
				li {
					list-style-type: none;
				}
			}
		}
		&:hover {
			background-color: #fff;
			border-color: #b1b1b1;
			&:before,
			&:after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				height: 100%;
				width: 6px;
				box-shadow: 0 0 6px 0 rgba(0,0,0,.45);
			}
			&:before {
				left: -1px;
			}
			&:after {
				right: -1px;
			}
			> .fa,
			> a {
				color: #cc0000;
			}
			> a {
				background-color: #fff;
				&:after {
					content: '';
					display: block;
					width: 32px;
					height: 6px;
					position: absolute;
					background-color: #fff;
					bottom: 0;
					left: 100%;
				}
				&:before {
					content: '';
					display: block;
					width: 10px;
					height: 100%;
					background-color: #fff;
					position: absolute;
					top: 0;
					right: -32px;
				}
			}
			.drop-body {
				display: block;
				box-shadow: 0 2px 6px 0 rgba(0,0,0,.45);
			}
		}

		&.icon {
			padding: 2px 10px 0 0;
			margin-top: 3px;
			> .fa {
				font-size: 26px;
				top: 3px;
				color: #7db40a;
			}
			&:hover {
				&:before,
				&:after {
					box-shadow: none;
				}
				.drop-body {
					a, span {
						padding: 2px 10px;
						font-size: 15px;
						margin: 0;
					}
					span {
						color: #333;
						text-decoration: none;
					}
					box-shadow: none;
					border-top: 0;
					width: 280px;
					ul {
						padding-left: 0;
						li {
							span {
								margin: 10px 0 5px;
								font-size: 17px;
							}
							ul {
								a {
									padding: 4px 10px;
								}
							}
						}
					}
				}
			}
		}
	}

}