/*
Icon classes can be used entirely standalone. They are named after their original file names.

```html
<i class="icon-name"></i>
```
*/
[class*="icon-"] {
	display: inline-block;
}

.icon-arr-next {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: -48px 0px;
  width: 26px;
  height: 45px;
}
.icon-arr-prev {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: -76px 0px;
  width: 26px;
  height: 45px;
}
.icon-close-big {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: 0px 0px;
  width: 46px;
  height: 45px;
}
.icon-close {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: -30px -69px;
  width: 15px;
  height: 15px;
}
.icon-karkas {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: -70px -47px;
  width: 32px;
  height: 17px;
}
.icon-motaj {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: 0px -47px;
  width: 34px;
  height: 20px;
}
.icon-poly {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: 0px -69px;
  width: 28px;
  height: 20px;
}
.icon-total {
  content: '';
  background-image: url('#{$img-path}sprite.png');
  background-repeat: no-repeat;
  background-position: -36px -47px;
  width: 32px;
  height: 19px;
}
