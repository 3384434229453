.elem-advantage {

	.advantage-header {
		display: table;
		margin-left: -17px;
		margin-bottom: 4px;
		.img-wrapper,
		.txt-name {
			display: table-cell;
			vertical-align: middle;
		}
		.txt-name {
			font-size: 24px;
			line-height: 32px;
			padding-left: 14px;
			padding-top: 16px;
			letter-spacing: 0.03em;
		}
	}
	> p {
		font-size: 15px;
		line-height: 1.5;
		letter-spacing: 0.01em;
		padding-right: 24px;
		margin-bottom: 0;
	}
}