.back-top {
  display: none;
  z-index: 2600;
  position: fixed;
  left: 0;
  bottom: 60px;
  width: 34%;
  margin-left: 50%;
  opacity: 0.3;
  filter: alpha(opacity=50);
}

.back-top:hover {
  opacity: 1;
  filter: alpha(opacity=100);
  cursor: pointer;
}

.back-top-bottom {
  z-index: 2600;
  position: absolute;
  display: block;
  left: 0px;
  bottom: 0;
  margin: 0 0 0 100%;
  padding: 32px 12px 4px;
  color: white;
  background: #7db40a url(/frontend/images/d/back-top.png) no-repeat 50% 11px;
  border-radius: 7px;
}