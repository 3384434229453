.box-complect {

	h1, .h1,
	h2, .h2 {
		font-family: 'beau';
		font-size: 36px;
		margin-bottom: 50px;
		letter-spacing: 0.03em;
	}
	> .row {
		margin-bottom: 60px;
	}
}