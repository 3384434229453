.youtube {
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  display: block;
  overflow: hidden;
  transition: all 200ms ease-out;
  cursor: pointer;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  margin: 0px;

  .play {
    background: #1f1f1f;
    border-radius: 12px;
    color: #FFFFFF;
    font-size: 14px; /* change this to change size */
    padding: 0;
    position: relative;
    text-align: center;
    text-indent: 0.2em;
    transition: all 150ms ease-out;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 68px;
    height: 48px;
    margin-left: -34px;
    margin-top: -24px;
    opacity:.81;
  }

  .play:hover {
    background: #cc181e;
    opacity:1;
  }

  .play::after {
    border-style: solid;
    border-width: 1em 0 1em 1.732em;
    border-color: transparent transparent transparent #fff;
    content: ' ';
    font-size: 0.75em;
    height: 0;
    margin: -1em 0 0 -0.75em;
    top: 50%;
    position: absolute;
    width: 0;
  }
}

