.box-step {
	padding: 10px 32px;
	border-radius: 20px;
	margin-top: 20px;
	background-color: rgba(248, 248, 248, 0.6);
	h3 {
		font-size: 24px;
		margin: 10px 0 40px;
		small {
			display: block;
			font-size: 15px;
			margin-top: 10px;
		}
	}
	table {

	}
}

.box-step-header {
	padding: 3px;
	margin-left: 16px;
	border-radius: 20px;
	background-color: rgba(248, 248, 248, 0.6);
	float: left;
	.num {
		color: #888;
		margin: 10px 15px 10px 20px;
		font-size: 25px;
		float: left;
	}
	h3 {
		margin: 10px 20px 10px 0;
		font-size: 17px;
		line-height: 1.2;
		float: left;
		color: #888;
		a {
			color: #888;
			text-decoration: none;
			cursor: default;
		}
	}
	&.active {
		.num {
			color: #333;
		}
		h3 {
			color: #333;
			a {
				cursor: pointer;
				color: #0035ac;
				text-decoration: underline;
			}
		}
	}
	&.current {
		h3 {
			a {
				cursor: default;
				color: #333;
				text-decoration: none;
			}
		}
	}
}