.box-delivery-price {
	margin-bottom: 60px;
	
	h1, .h1,
	h2, .h2 {
		font-family: 'beau';
		font-size: 36px;
		margin-bottom: 38px;
		letter-spacing: 0.03em;
	}
	
	.item-set {
		font-size: 0;
		@extend .justify-content;
	}
	.item-delivery {
		display: inline-block;
		vertical-align: top;
		white-space: nowrap;
		> b, 
		> span {
			display: block;
		}
		> b {
			font-size: 20px;
			line-height: 1;
			margin-bottom: 6px;
		}
		> span {
			font-size: 16px;
			line-height: 1;
			margin-bottom: 15px;
		}
		.price {
			font-size: 30px;
			color: #517800;
			small {
				font-size: 22px;
			}
		}
	}
}