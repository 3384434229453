.radio-check-big {
  display: table;
  position: relative;
  input[type="checkbox"],
  input[type="radio"] {
    @include opacity(0);
    position: absolute;
    + span {
      &:before,
      &:after {
        @include transition(all linear .2s);
      }
    }
  }
  input[type="checkbox"] + span,
  input[type="radio"] + span {
    display: inline-block;
    position: relative;
    font-weight: normal;
    cursor: pointer;
    font-size: 20px;
    color: #333;
    padding-left: 38px;
    padding-top: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    @include transition(all linear .2s);
    text-align: left;
    small {
      display: block;
      color: #888;
      font-size: 14px;
    }
    img,table {
      margin-top: 10px;
      border-radius: 15px;
      border: 3px solid #f5f5f5;
      width: 300px;
      height: 170px;
      background-color: #f5f5f5;
    }
    ul {
      font-size: 14px;
      padding-left: 0px;
      color: #333;
      font-weight: 400;
      list-style-type: none;
      li {
        i.fa {
          &.fa-plus-circle {
            color:green;
          }
          &.fa-minus-circle {
            color:red;
          }
        }
      }
    }

    .table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, table>tbody>tr>td, table>tfoot>tr>td {
      border: 0;
    }
    table>tbody>tr>td:first-child {
      width: 100px;
      background-repeat: no-repeat;
      border-radius: 10px 0 0 10px;
    }
    table>tbody>tr>td:last-child {
      width: 180px;
    }
    &:hover {
      color: $link-hover-color;
      &:before {
        //background-color: #e9e9e9;
      }
    }
    &:before {
      position: absolute;
      left: 0;
      top: 3px;
      content: "";
      display: inline-block;
      height: 25px;
      width: 25px;
      border: solid 3px #7db40a;
      @include transition(all linear .2s);
    }
    &:active {
      &:before {
        box-shadow: inset 0 0 1px 0px #b4aeb0;
      }
    }
  }
  input[type="checkbox"]:disabled + span,
  input[type="radio"]:disabled + span {
    cursor: default;
    cursor: not-allowed;
    color: $text-muted;
    @include opacity(0.7);
    &:before {
      border-color: #c3c3c1;
    }
    &:hover {
      color: $text-muted;
    }
  }
  input[type="checkbox"]:disabled:checked + span,
  input[type="radio"]:disabled:checked + span {
    &:hover {
      color: $text-muted;
    }
  }
  input[type="radio"] + span:before {
    border-radius: 50%;
  }
  // Checkbox
  input[type="checkbox"] + span:before {
    border-radius: 3px;
  }
  input[type="checkbox"] {
    &:checked,
    &[checked="checked"] {
      & + span {
        &:before {

        }
        &:after {
          content: '\f00c';
          display: inline-block;
          font: normal normal normal 14px / 1 FontAwesome;
          font-size: 24px;
          position: absolute;
          left: 1px;
          top: -1px;
          color: #7db40a;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        &:hover {
          color: $link-hover-color;
          &:before {
            background-color: #e6ede6;
          }
        }
      }
    }
  }
  // Radio
  input[type="radio"] {
    & + span:before {
      top: 2px;
      left: -1px;
    }
    &:checked,
    &[checked="checked"] {
      & + span {
        color: #7db40a;
        font-weight: 600;
        &:before {
        }
        &:after {
          content: '\f00c';
          display: inline-block;
          font: normal normal normal 14px FontAwesome;
          font-size: 30px;
          position: absolute;
          left: -1px;
          top: -4px;
          color: #7db40a;
          text-rendering: auto;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }

        small {
          font-weight: 400
        }
        img, table {
          margin-top: 10px;
          border-radius: 15px;
          border-color: #7db40a;
        }

        &:hover {
          &:before {
            //border-color: linear;
            //background-color: #e7ece8;
          }
          &:after {
            //background-color: #7db40a;
          }
        }
      }
      &:disabled,
      &[disabled="disabled"] {
        & + span {
          &:before {
            border-color: #c3c3c1;
          }
          &:after {
            background-color: #7db40a;
          }
        }
      }
    }
  }
}