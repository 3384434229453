.elem-img {
	margin: 17px 0;
	img {
		display: block;
		max-width: 100%;
		margin: 0 auto;
	}
	.descr {
		margin-top: 10px;
        font-size: 13px;
	}
	&.pull-left,
	&.pull-right {
		max-width: 50%;
		margin-top: 6px;
		margin-bottom: 3px;
	}
	&.pull-left {
		margin-right: 20px;
	}
	&.pull-right {
		margin-left: 20px;
	}
}
.fotorama-wrapper {
	.elem-img {
		position: relative;
		&:after {
			content: '';
			display: block;
			width: 32px;
			height: 32px;
			background: url('#{$img-path}fotorama.png') 0 -32px no-repeat;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
}