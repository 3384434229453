.box-price-table {
	margin-bottom: -6px;

	h1, .h1,
	h2, .h2 {
		font-family: 'beau';
		font-size: 36px;
		margin-top: 0;
		margin-bottom: 34px;
		letter-spacing: 0.03em;
	}

	.width-collection {
		font-size: 24px;
		margin-bottom: 24px;

		nav {
			display: inline-block;
    		margin-left: 16px;
    		a {
    			font-size: 30px;
			    text-decoration: none;
			    display: inline-block;
			    border-radius: 4px;
			    padding: 4px 20px 7px 20px;
			    span {
			    	display: inline-block;
					background: -moz-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: -ms-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: -o-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: -webkit-gradient(linear, 0 0, 100% 0, from(#0035ac), color-stop(0.6, #0035ac), color-stop(0.6, transparent));
					background: -webkit-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background-size: 5px 2px;
					background-repeat: repeat-x;
					background-position: 0 85%;
					letter-spacing: -0.01em;
			    }
			    &:hover,
				&:focus {
					outline: none;
					> span {
						display: inline-block;
						background: -moz-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: -ms-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: -o-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: -webkit-gradient(linear, 0 0, 100% 0, from(#c00), color-stop(0.6, #c00), color-stop(0.6, transparent));
						background: -webkit-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background-size: 5px 2px;
						background-repeat: repeat-x;
						background-position: 0 85%;
						letter-spacing: -0.01em;
					}
				}
				&:active {
					outline: none;
				}
			    &.active {
			    	background-color: $btn-green-bg;
				    cursor: default;
				    color: #fff;
				    > span {
						background: none;
					}
			    }
    		}
		}
	}

	.table-price {
		margin-bottom: 20px;
		table-layout: fixed;
		.colWidth {
			width: 12.4%;
		}
		.colKarkas {
			width: 24%;
		}
		.colPoly {
			width: 24.5%;
		}
		.colTotal {
			width: 22%;
		}
		.colored {
			background-color: #f5f5f5;
		}
		thead {
			th, td {
				border-bottom: solid 2px #7db40a;
				font-size: 20px;
				font-weight: normal;
				padding: 6px 13px;
				border-radius: 4px 4px 0 0;
				vertical-align: middle;
				letter-spacing: 0.03em;
				&:first-child {
					font-size: 24px;
					padding-left: 0;
					padding-right: 0;
					letter-spacing: normal;
				}
				> i[class^="icon-"] {
					margin-right: 8px;
				}
			}
		}
		tbody {
			font-size: 24px;
			small {
				color: #848484;
			}
			input[type="text"] {
				display: inline-block;
				border: none;
				outline: none;
				//width: 75px;
				height: 50px;
				text-align: right;
				background-color: transparent;
			}
			.mathsign {
				color: #7db40a;
				display: inline-block;
				font-size: 30px;
				margin-right: 13px;
				line-height: 1;
				position: relative;
				top: 3px;
			}
			.meter {
				display: inline-block;
				border: solid 2px #7db40a;
				border-radius: 4px;
				padding: 6px 0;
				width: 82px;
				text-align: center;
			}
			.colKarkas {
				padding-left: 48px;
			}
			.colPoly {
				padding-left: 21px;
				input[type="text"] {
					margin-left: -25px;
				}
			}
			.colTotal {
				padding-left: 21px;
				input[type="text"] {
					margin-left: -15px;
				}
			}
			.colMontaj {
				text-align: right;
				padding-right: 25px;
			}
			td {
				vertical-align: middle;
				padding-bottom: 4px;
				position: relative;
				white-space: nowrap;

				&:after {
				/*
					content: '';
					display: block;
					position: absolute;
					left: 0;
					top: 2px;
					bottom: 4px;
					right: 0;
					border-top: solid 2px;
					border-bottom: solid 2px;
					border-color: transparent;
				*/
				}
				&:first-child {
					padding-left: 0;
					&:after {
						border-left: solid 2px transparent;
						border-radius: 4px 0 0 4px;
					}
				}
				&:last-child {
					&:after {
						border-right: solid 2px transparent;
						border-radius: 0 4px 4px 0;
					}
				}
			}
			tr {
				&:first-child {
					td {
						padding-top: 15px;
						&:after {
							top: 15px;
						}
					}
				}
				&:hover {
					td {
						.meter {
							background-color: #7db40a;
							color: #fff;
						}
						&:after {
							border-color: #7db40a;
						}
					}
				}
				&.row_increment {
					td {
						color: #848484;
						padding-top: 22px;
						&:after {
							content: none;
							display: none;
						}
					}
					.meter {
						color: #fff;
						border-color: #bdbdbd !important;
						background-color: #bdbdbd !important;
						cursor: pointer;
					}
				}
			}
		}
	}
}