.box-footer {
	background-color: #eee;
	height: $footer-height;
	min-width: 990px;
	padding-top: 25px;

	.footer-nav {
		> a {
			display: inline-block;
			text-align: left;
			font-size: 16px;
			text-decoration: underline;
			color: #333;
			margin-right: 50px;
		}
	}
	.info-footer {
		padding-top: 20px;
		.info {
			display: inline-block;
			vertical-align: top;
			font-size: 14px;
			@include opacity(0.7);
		}
	}
	.copyright {
		display: inline-block;
		vertical-align: top;
		font-size: 14px;
		margin-top: 42px;
	}
	.time-tel {
		display: inline-block;
		vertical-align: top;
		margin-top: 39px;
		.phone {
			font-size: 20px;
			display: inline-block;
			vertical-align: top;
			letter-spacing: -0.02em;
		}
		.time {
			font-size: 14px;
			display: inline-block;
			vertical-align: top;
			padding-top: 3px;
    		margin-left: 15px;
		}
	}
}