.box-advantages {
	padding-top: 10px;
	margin-bottom: 36px;

	h1, .h1,
	h2, .h2 {
		font-family: 'beau';
		font-size: 36px;
		margin: 0 0 20px 0;
		letter-spacing: 0.03em;
	}
	p {
		line-height: 1.6;
		img {
			margin: 15px 0;
		}
	}
}