.elem-boot_line {
  border: 1px solid #7db40a;
  width: 100%;
  height: 8px;
  border-radius: 8px;
  .line {
    background-color: #7db40a;
    height: 6px;
    border-radius: 6px;
    @for $pct from 1 through 10 {
      &.pct-#{$pct*10} {
        width: $pct*10%;
      }
    }
  }
}