.justify-content {
	text-align: justify;
    -ms-text-justify: distribute-all-lines;
    text-justify: distribute-all-lines;
    &:after {
    	content: '';
    	display: inline-block;
    	width: 100%;
    }
}


/* Positioning */
.pos-relative {
	position: relative;
}
.pos-static {
	position: static;
}
.pos-absolute {
	position: absolute;
}

/* Margins */
.marg-top_0,
.no-marg-top {
	margin-top: 0;
}
.marg-bot_0,
.no-marg-bot {
	margin-bottom: 0;
}
.marg-left_0,
.no-marg-left {
	margin-left: 0;
}
.marg-right_0,
.no-marg-right {
	margin-right: 0;
}

/* Paddings */
.pad-top_0,
.no-pad-top {
	padding-top: 0;
}
.pad-bot_0,
.no-pad-bot {
	padding-bottom: 0;
}
.pad-left_0,
.no-pad-left {
	padding-left: 0;
}
.pad-right_0,
.no-pad-right {
	padding-right: 0;
}

.pad-sides_0,
.no-pad-sides {
  padding-left: 0;
  padding-right: 0;
}