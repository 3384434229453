.elem-slider-btn {
	display: inline-block;
	color: #7db40a;
	font-size: 15px;
	&:hover{
		color: #659a00;
	}
	&:focus {
		color: #7db40a;
		&:hover {
			color: #659a00;
		}
	}
}