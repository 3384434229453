.box-query {
	display: table;
	width: 100%;
	background-color: #62a70a;
	color: #fff;
	border-radius: 4px;
	margin-top: 64px;
	margin-bottom: 56px;

	.query-head,
	.query-txt,
	.query-btn {
		display: table-cell;
		vertical-align: middle;
	}
	.query-head {
		font-size: 28px;
	    line-height: 34px;
	    padding: 15px 15px 18px 27px;
	    letter-spacing: 0.02em;
	    width: 34%;
	    background-color: #80c10f;
	    border-radius: 4px 0 0 4px;
	}
	.query-txt {
		font-size: 15px;
	    line-height: 22px;
	    letter-spacing: 0.015em;
	    width: 28%;
	    padding-right: 25px;
	    background-color: #80c10f;
	    position: relative;
	    &:after {
	    	content: '';
	    	display: block;
	    	width: 22px;
	    	height: 100%;
	    	background: url('#{$img-path}bg-query.png') 0 0 no-repeat;
	    	position: absolute;
	    	right: 0;
	    	top: 0;
	    }
	}
	.query-btn {
		padding-right: 30px;
		text-align: right;
	}
	.btn {
		font-size: 24px;
		padding: 9px 19px;
	}

	&.two-col {
		.query-head {
			width: 61.5%;
			padding-right: 25px;
		    position: relative;
		    white-space: nowrap;
		    &:after {
		    	content: '';
		    	display: block;
		    	width: 22px;
		    	height: 100%;
		    	background: url('#{$img-path}bg-query.png') 0 0 no-repeat;
		    	position: absolute;
		    	right: 0;
		    	top: 0;
		    }
		    a {
		    	color: #fff;
		    	&:hover {
		    		color: $link-hover-color;
		    	}
		    }
		}
		.query-btn {
			width: auto;
			text-align: left;
			padding-left: 40px;
			.query-phone {
				font-size: 36px;
				font-family: 'beau';
				letter-spacing: -0.035em;
				line-height: 1.1;
				white-space: nowrap;
			}
			.query-hint {
				font-size: 15px;
			}
		}
	}
}