.box-slider-tabs {
	margin-top: 20px;
	margin-bottom: 30px;

	.tabs-header {
		font-size: 24px;
		nav {
			display: inline-block;
			margin-left: 16px;
			a {
				font-size: 30px;
				text-decoration: none;
				display: inline-block;
				border-radius: 4px 4px 0 0;
				padding: 4px 20px 17px 20px;
				span {
					display: inline-block;
					background: -moz-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: -ms-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: -o-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: -webkit-gradient(linear, 0 0, 100% 0, from(#0035ac), color-stop(0.6, #0035ac), color-stop(0.6, transparent));
					background: -webkit-linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background: linear-gradient(left, #0035ac, #0035ac 60%, transparent 60%);
					background-size: 5px 2px;
					background-repeat: repeat-x;
					background-position: 0 85%;
					letter-spacing: -0.01em;
				}
				&:hover,
				&:focus {
					outline: none;
					> span {
						display: inline-block;
						background: -moz-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: -ms-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: -o-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: -webkit-gradient(linear, 0 0, 100% 0, from(#c00), color-stop(0.6, #c00), color-stop(0.6, transparent));
						background: -webkit-linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background: linear-gradient(left, #c00, #c00 60%, transparent 60%);
						background-size: 5px 2px;
						background-repeat: repeat-x;
						background-position: 0 85%;
						letter-spacing: -0.01em;
					}
				}
				&:active {
					outline: none;
				}
				&.active {
					background-color: $btn-green-bg;
					cursor: default;
					color: #fff;
					> span {
						display: inline-block;
						background: none;
					}
				}
			}
		}
	}

	.tabs-body {
		border-top: solid 2px $btn-green-bg;
		background: url('#{$img-path}bg-banner-slider.png') 50% 0 no-repeat;
		min-height: 620px;
	}
	.slider-banner {
		position: relative;
		.elem-slider-btn {
			font-size: 75px;
			position: absolute;
			top: 187px;
			&.prev {
				left: -60px;
			}
			&.next {
				right: -60px;
			}
			&:focus,
			&:active {
				outline: none;
			}
		}
		.img-wrapper {
			position: relative;
			margin-top: 55px;
			> img {
				max-width: 100%;
			}
			.circle {
				position: absolute;
				left: 0;
				top: -10px;
				display: block;
				width: 135px;
				height: 135px;
				border-radius: 50%;
				overflow: hidden;
				text-align: center;
				text-decoration: none;
				color: #fff;
				background-color: #4a6d00;
				padding-top: 47px;
				> span {
					border-bottom: dashed 1px transparent;
					position: relative;
					z-index: 1;
					font-size: 18px;
					line-height: 22px;
				}
				> img {
					position: absolute;
					top: 50%;
					left: 50%;
					@include translate(-50%, -50%);
					@include opacity(0.8);
				}
				&:hover {
					color: $link-color;
					> span {
						border-bottom-color: $link-color;
						z-index: 2;
					}
					&:before,
					&:after {
						content: '';
						display: block;
						width: 100%;
						height: 100%;
						position: absolute;
						left: 0;
						top: 0;
						border-radius: 50%;
					}
					&:before {
						z-index: 3;
						box-shadow: inset 0 0 0 4px #659a00;
					}
					&:after {
						z-index: 1;
						background-color: #fff;
						@include opacity(0.8);
					}
				}
			}
		}
		.info-banner {
			margin-top: 35px;
			h1 {
				font-family: 'beau';
				font-size: 36px;
				line-height: 1.05;
				margin-bottom: 16px;
				margin-top: 0;
				div {
					font-size: 20px;
					line-height: 2.1;
					font-weight: bold;
					margin-bottom: 11px;
				}
			}
			.info-banner-head {
				font-family: 'beau';
				font-size: 36px;
				line-height: 1;
				margin-bottom: 16px;
				margin-top: 0;
			}
			.info-banner-subhead {
				font-size: 20px;
				line-height: 1.3;
				margin-bottom: 12px;
				background: #6da409;
				color: #fff;
				padding: 3px 0 4px 12px;
				font-family: PT Sans;
			}
			p {
				line-height: 1.5;
				margin-bottom: 26px;
			}
			form {
				padding-top: 4px;
			}
			.form-group {
				padding-bottom: 16px;
				&.has-error {
					.form-control {
						border-color: $state-danger-text;
					}		
				}
			}
			.form-control {
				width: 100%;
				height: 41px;
				border-color: #818181;
				background-color: #eee;
				box-shadow: inset 0 5px 5px 0 rgba(0,0,0,.1);
				font-size: 20px;
			}
			.elem-hint {
				display: inline-block;
				margin-top: 8px;
			}
		}
	}
}