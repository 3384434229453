.box-header {
	padding-top: 10px;
	margin-bottom: 7px;

	.logo {
		display: inline-block;
		vertical-align: top;
		width: 111px;
		height: 82px;
		> img {
			max-width: 100%;
		}
	}
	.slogan {
		font-family: 'PT Sans';
		font-size: 16px;
		color: #333;
		display: inline-block;
		vertical-align: top;
		padding: 4px 0 0 19px;
	}
	.achievement {
		font-family: 'Open Sans';
		display: inline-block;
		vertical-align: top;
		color: #333;
		position: absolute;
		left: 50%;
		@include translate(-50%, 0);
		margin-left: 43px;
		margin-top: -4px;
		.num {
			display: inline-block;
		    vertical-align: top;
		    float: left;
		    font-size: 59px;
		    letter-spacing: -0.01em;
		}
		.txt {
			float: left;
		    font-size: 24px;
		    padding-left: 20px;
		    display: inline-block;
		    vertical-align: top;
		    line-height: 27px;
			padding-top: 16px;
		}
	}
	.head-info {
		.city {
			position: relative;
			display: block;
			font-size: 15px;
			padding-left: 19px;
			.fa {
				position: absolute;
				color: #608a07;
				left: 4px;
			    top: 2px;
			    font-size: 18px;
			}
			&:before {
				content: '';
				display: block;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				background-color: #608a07;
				position: absolute;
				left: 6px;
    			top: 4px;
			}
		}
		.phone {
			display: block;
		    font-family: 'Open Sans';
		    font-size: 28px;
		    margin-bottom: -2px;
		}
		.work-time {
			display: block;
			font-size: 15px;
			padding-left: 4px;
		}
	}
}