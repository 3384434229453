.elem-file {
	display: inline-block;
	position: relative;
	padding-left: 51px;
	a {
		display: block;
		font-size: 15px;
		.fa {
			position: absolute;
		    left: 0;
		    top: -4px;
		    color: #333;
		    font-size: 40px;
		}
	}
	span {
		display: block;
		font-size: 12px;
		line-height: 1;
		font-family: Arial;
	}
}