.nav-side {
	list-style: none;
	margin: 5px 0 0 0;
	padding: 10px 0 17px 0;
	background-color: #eee;
	border-radius: 4px;
	a {
		text-decoration: underline;
		display: inline-block;
		width: 100%;
		border-radius: 4px;
	}
	> li {
		&.active {
			> a {
				font-size: 20px;
				background-color: #7db40a;
				color: #fff;
				text-decoration: none;
			}
		}
		> a {
			&.active {
				text-decoration: none;
				color:#333;
			}
			font-size: 17px;
			padding: 8px 20px;
		}
		> ul {
			list-style: none;
			margin: 0 0 -4px 19px;
			padding: 0;
			> li {
				margin-bottom: 4px;
				a {
					&.active {
						text-decoration: none;
						color:#333;
					}
					font-size: 15px;
					padding: 8px 20px;
				}
			}
		}
	}
}