.box-banner {
	height: 156px;
	padding: 28px 0 27px 0;
	position: relative;

	.text {
		font-family: "PT Sans";
		float: left;
		width: 746px;
		height: 101px;
		padding: 14px 90px 0px 27px;
		background-color: #7faf1b;
		border-radius: 4px 0 0 4px;
		font-size: 28px;
		color: #fff;
		line-height: 34px;
		position: relative;
		z-index: 1;
		letter-spacing: 0.017em;
		&:after {
			content: '';
			display: block;
			width: 143px;
			height: 101px;
			position: absolute;
			right: -32px;
			top: 0;
			background: url('#{$img-path}decor-leaves.png') 0 -28px no-repeat;
		}
	}
	> img {
		position: absolute;
		right: 0;
		top: 28px;
		border-radius: 0 4px 4px 0;
	}
}