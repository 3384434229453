.elem-complect {
	@include clearfix();
	.zoomer {
		display: inline-block;
		vertical-align: top;
		width: 108px;
		float: left;
		position: relative;
		> img {
			max-width: 100%;
		}
		.fa-search {
			position: absolute;
			left: 0;
			top: 0;
			background: #7db40a;
			border-radius: 0 0 3px 0;
			color: #fff;
			width: 20px;
			height: 20px;
			font-size: 13px;
			&:before {
				position: absolute;
				left: 4px;
				top: 2px;
			}
		}
	}
	.text {
		display: inline-block;
		vertical-align: top;
		font-size: 15px;
		line-height: 22px;
		float: left;
		width: 64%;
		padding-left: 20px;
		margin-top: -5px;
	}
}